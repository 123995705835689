/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { jsx } from '@emotion/react'
import TurmasPOA from "@/components/UnidadePOATab"
import { Link } from "gatsby"
import EstruturaImages from "@/components/EstruturaPOA"
import IcoMore from '../assets/images/lp/unidades/icons/more.svg'
import MaskInput from 'react-awesome-mask-input';

import { 
  headerContent,
  turmasContent,
  boxAgendeSuaVisita,
  btnEnviar,
  locacalizaoContato,
  faleConosco,
  mostrarFotos,
  DDesktop,
  DMobile,
}  from '../assets/styles/UnidadesBase.styles'

const UnidadesBasePage = () => {
  return (
    <Fragment>
      <section css={headerContent}>
        {/* <Container data-aos="fade-up" data-aos-delay={"400"}> */}
        <Container>
          <Row style={{ marginBottom: 32 }}>
            <Col lg={10} md={10} sm={12}>
              <h1>Porto Alegre, RS</h1>
              <h4>Rua Auxiliadora, 191</h4>
            </Col>
          </Row>
          <Row css={[mostrarFotos]}>
            <Col>
              <Link to='/estrutura-poa'><img src={IcoMore} /> Mostrar todas as fotos</Link>
            </Col>
          </Row>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <EstruturaImages />
            </Col>
          </Row>
          {/* <Row css={[mostrarFotos, DMobile]}>
            <Col>
              <Link to='/estrutura-poa'><img src={IcoMore} /> Mostrar todas as fotos</Link>
            </Col>
          </Row> */}
        </Container>
      </section>
      <section css={turmasContent}>
        <Container>
          <Row>
            <Col lg={8} md={8} sm={12}>
              <h2>Turmas Disponíveis</h2>
              <div>
                <TurmasPOA />
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div css={boxAgendeSuaVisita}>
                <h4>Agende uma Visita</h4>
                <form
                  name='agendar-visita-poa'
                  method='POST'
                  action='/sucesso'
                  data-netlify='true'
                >
                  <input
                    type='hidden'
                    name='form-name'
                    value='agendar-visita-poa' 
                  />
                  <p>
                    <input type="text" name="name" placeholder="Nome" required/>
                  </p>
                  <p>
                    <MaskInput
                      type="text"
                      mask={['(99) 9999-9999', '(99) 9 9999-9999']}
                      placeholder="Telefone"
                      name='telefone'
                      required
                    />
                  </p>
                 <p>
                    <input type="datetime-local" name="data-visita" placeholder="Escolha uma hora" required />
                  </p>
                  <button css={btnEnviar} type="submit">Agendar Visita</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={locacalizaoContato}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2>Onde estamos</h2>
              <p>Rua Auxiliadora, 191</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
              <iframe style={{ border: 'none', width: '100%', height: 400 }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13818.210023800135!2d-51.1912262!3d-30.0210032!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951979d8aec70df7%3A0x99abc7ec0bb32de0!2sBertoni%20PRO%20-%20Porto%20Alegre!5e0!3m2!1spt-BR!2sbr!4v1690221434300!5m2!1spt-BR!2sbr"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
              </div>
            </Col>
          </Row>
          <Row css={faleConosco}>
            <Col lg={12} md={12} sm={12}>
              <h2>Quer falar com a gente?</h2>
              <div>
                <form name="fale-conosco-unidade-poa" method="POST" action='/sucesso' data-netlify-recaptcha="true" data-netlify="true">
                  <input type="hidden" name="form-name" value="fale-conosco-unidade-poa" />
                  <p>
                    <input type="text" name="name" placeholder="Nome" required/>
                  </p>
                  <p>
                    <input type="number" name="email" placeholder="E-mail" required />
                  </p>
                  <textarea name="mensagem" rows="5" cols="50" placeholder="Envie uma mensagem" />
                  <button css={btnEnviar} type="submit">Enviar</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default UnidadesBasePage;
